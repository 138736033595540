import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import CtaImg from './assets/images/cta.inline.svg';

import SalesButton from '../../SalesButton/SalesButton';

const IndexCTA: React.FC = () => (
  <>
  <section className="py-layout-6">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <CtaImg className="img-fluid"/>
        </div>
        <div className="col-md-6">
          <h2 className="mt-7 mt-md-0">
            <FormattedMessage id="index.cta.title"
              values={defaultFormatValues}
            />
          </h2>
          <p className="lead">
            <FormattedMessage id="index.cta.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <div className="button-responsive-row mt-7">
            <SalesButton big={true} positionTag="bottom"><FormattedMessage id="buttons.sales"/></SalesButton>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="pre-footer-bg"/>
  </>
);

export default IndexCTA;
